<script>
import Layout from "@/router/layouts/main";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    Stat,
  },
  data() {
    return {
      statData: [
        {
          type: "line",
          title: "Total de clientes",
          value: "0",
          icon: "bx-purchase-tag-alt",
        },
      ],
    };
  },
  methods: {
  },
  mounted() {
  }
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/sales">Vendas</router-link></li>
      <li class="breadcrumb-item">Clientes</li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>Clientes</h3>
        <p></p>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-6 col-lg-3 col-xl-3">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="card">
      <div class="card-body min-vh-50 d-flex justify-content-center align-items-center">
        <div class="text-center">
          <i class="far fa-sad-cry font-size-24"></i><br>
          nenhum cliente encontrato
        </div>
      </div>
    </div>
  </Layout>
</template>